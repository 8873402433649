<template>
  <div class="switching">
    <Header />
    <HeaderBottom />
    <b-container class="switching-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link to="/orders"
            ><b-icon class="mx-2" icon="chevron-left"></b-icon
            ><span>Kembali</span>
          </b-link>
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="switching-total-detail mb-4">
          <div class="review-title">
            <b-row class="justify-content-center my-2">
              <h4>Pengalihan Produk Reksa Dana</h4>
            </b-row>
          </div>
          <div class="order-detail">
            <b-row class="mt-4">
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-left">
                <p class="order-title">No.Order</p>
                <h2 class="order-number">
                  {{ order.code }}
                </h2>
              </div>
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-right">
                <p class="date-title">Tanggal</p>
                <h3 class="date">
                  {{ moment(order.created_at).format(" DD MMM YYYY") }}
                </h3>
              </div>
            </b-row>
          </div>
          <hr />
          <div class="switching-from">
            <b-row class="mt-4 mb-2">
              <div class="col-6 my-auto text-left">
                <p class="title">Dari</p>
                <p class="fund-name">
                  {{
                    order.product_out != null ? order.product_out.fund_name : ""
                  }}
                </p>
                <h2 class="fund-type">{{ order.product_out.fund_type }}</h2>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="unit-value">
                  {{ formatNab(order.switch_out_unit) }} unit
                </p>
              </div>
            </b-row>
          </div>
          <img src="@/assets/img/icons/transactions/arrow-down-circle.webp" />
          <b-row class="mt-4">
            <div class="switching-to">
              <p class="title ml-3">Ke</p>
            </div>
          </b-row>
          <div class="fund-name">
            <b-row>
              <div class="col-2 col-lg-1 text-left mb-2">
                <div v-if="order.product_in.image == null">
                  <img
                    :src="
                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      order.product_in.fund_name
                    "
                  />
                </div>
                <div v-else>
                  <img
                    :src="`${imageCore}/images/products/${order.product_in.image}`"
                    alt="Fund Image"
                  />
                </div>
              </div>
              <div class="col-10 px-4 text-left">
                <h4>
                  {{
                    order.product_in != null ? order.product_in.fund_name : ""
                  }}
                </h4>
                <p>{{ order.product_in.fund_type }}</p>
              </div>
            </b-row>
          </div>

          <template v-if="this.order.fee_sales != null">
            <div class="total-switching">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Jumlah Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ order.product_out.fund_ccy }}{{ formatPrice(totalTrx) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <!-- <div class="switching-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Pengalihan {{ order.fee_sales }}%</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="variant">
                    {{ order.product_out.fund_ccy }}{{ formatPrice(feeSwitch) }}
                  </p>
                </div>
              </b-row>
            </div> -->
            <hr />
            <div class="unit-estimate-accepted">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Estimasi unit diterima</p>
                  <p class="unit-price">
                    Harga/unit
                    {{ moment(order.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{
                    formatNab(((order.switch_out_unit *
                      order.product_out.nav.nav_per_unit) /
                      order.product_in.nav.nav_per_unit))
                    }}
                    unit
                  </p>
                </div>
              </b-row>
            </div>
          </template>

          <template v-else>
            <div class="total-switching">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Jumlah Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ order.product_out.fund_ccy
                    }}{{ formatPrice(order.switch_out_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <!-- <div class="switching-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p v-if="user.individual.sales_id != null" class="variant">
                    {{
                      order.product_in.fee_switch_text
                        ? order.product_in.fee_switch_text
                        : "-"
                    }}
                  </p>
                  <p v-else class="value">Gratis</p>
                </div>
              </b-row>
            </div> -->
            <hr />
            <div class="unit-estimate-accepted">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Unit diterima</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{
                    formatNab(((order.switch_out_unit *
                      order.product_out.nav.nav_per_unit) /
                      order.product_in.nav.nav_per_unit))
                        
                    }}
                    unit
                  </p>
                </div>
              </b-row>
            </div>
          </template>
        </b-card>

        <b-card class="switching-total-detail mb-4">
          <div class="transaction-status ml-3">
            <b-row>
              <p>Status Transaksi</p>
            </b-row>
          </div>

          <div v-if="order.status == 'success'">
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pengalihan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon
                        font-scale="1.5"
                        class="text-muted"
                        icon="check2-circle"
                      ></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Verifikasi oleh Sales</p>
                    <p class="detail mt-n2">
                      <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                      <!-- WIB -->
                      <!-- 7 Jun 2021, 11:39 WIB -->
                    </p>
                  </div>
                </div>
              </template>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pengalihan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Verifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Diverifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Dalam Proses</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Berhasil</p>
                  <p class="detail">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 14:39 WIB -->
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pengalihan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon
                        font-scale="1.5"
                        class="text-muted"
                        icon="check2-circle"
                      ></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Verifikasi oleh Sales</p>
                    <p class="detail mt-n2">
                      <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                      <!-- WIB -->
                      <!-- 7 Jun 2021, 11:39 WIB -->
                    </p>
                  </div>
                </div>
              </template>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Verifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Diverifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Dalam Proses</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 11:39 WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="x-circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pengalihan Gagal</p>
                  <p class="detail">
                    <!-- {{ moment(order.created_at).format(" DD MMM YYYY, H:mm:ss") }} -->
                    <!-- WIB -->
                    <!-- 7 Jun 2021, 14:39 WIB -->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-card>

        <b-card class="switching-total-detail mb-4">
          <div class="price-terms mt-4">
            <b-row>
              <div class="col-lg-12 col-md-6">
                <p>
                  <img
                    src="@/assets/img/icons/product/transaction-rules.svg"
                    alt="Ketentuan Transaksi"
                    class="mx-2"
                  />Ketentuan Transaksi
                </p>
              </div>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Transaksi sebelum 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="before-detail">
                Batas waktu konfirmasi Pembelian Reksa Dana paling lambat
                diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul 13:00
                WIB untuk diproses menggunakan harga NAV/Unit di hari bursa yang
                sama.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-after ml-3">
            <b-row class="mt-2">
              <p class="after">Transaksi setelah 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="after-detail">
                Transaksi Pembelian yang diterima dan tercatat melebihi batas
                waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                NAV/Unit hari bursa berikutnya.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Biaya dan Perpajakan</p>
            </b-row>
            <b-row>
              <p class="before-detail">
                Biaya dan Perpajakan akan timbul atas dasar ketentuan peraturan
                perundangan-undangan yang berlaku, sesuai dengan transaksi yang
                dimiliki.
              </p>
            </b-row>
          </div>
        </b-card>
        <b-button block to="/orders" class="mt-2 btn-tertiary py-2"
          >Lihat Aktivitas Dalam Proses</b-button
        >
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "OrderHistorySwitching",
  metaInfo: {
    title: "Detail Riwayat Pengalihan | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  data() {
    return {
      order: {},
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
  },
  created() {
    this.ifuaNumber = this.$route.params.ifua;
    this.orderId = this.$route.params.order_id;
    this.orderType = this.$route.params.order_type;
    this.fetchOrderGet();
  },
  methods: {
    async fetchOrderGet() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}order-detail?ifua_number=${this.ifuaNumber}&order_id=${this.orderId}&order_type=${this.orderType}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.access_token}`,
        },
      }).catch(e => {
        if (e.response.data.data === "order_type not found") {
          return this.$router.push("/NOT-FOUND");
        }
      });
      this.order = response.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.switching {
  background: #f3f4f6;
}
.switching-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.switching-total-detail {
  padding: 20px;
  width: 600px;
}

h1,
h2,
h3,
h4 p,
span {
  font-family: $inter;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
    margin-top: 2px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.total-switching {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.nav-unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.units-accepted {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .unit-price {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.order-detail {
  .order-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
    margin-bottom: 6px;
  }
  .order-number {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
  }

  .date-title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 6px;
  }
  .date {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
  }
}

.switching-from {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #374151;
    margin-bottom: 6px;
  }
  .fund-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    margin-bottom: 6px;
  }

  .fund-type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
  .unit-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.switching-to {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #374151;
    margin-bottom: 6px;
  }
}

.switching-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.unit-estimate-accepted {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
    margin-bottom: 4px;
  }

  .unit-price {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.stepper {
  .line {
    width: 2px;
    background-color: lightgrey !important;
  }
  .lead {
    font-size: 1.1rem;
  }
}

.unsuccess-transaction-status {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #5c0dab;
  }
}

.transaction-status p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.success-transaction-status {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}
.switching-success {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.estimation-complete {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }
}

.btn-tertiary {
  font-family: $inter;
  background: $primaryColor;
  border-radius: 4px;
  width: 600px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.btn-tertiary:hover {
  background: $hoverPrimary;
}

.btn-tertiary:focus {
  box-shadow: none !important;
}

.btn-tertiary:active {
  background: $hoverPrimary !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }
  p {
    font-size: 14px !important;
  }
}
</style>
